import { useEffect, useRef } from "react";

export const useCaptureEscapeKey = (onEscape: () => void) => {
  const onEscapeRef = useRef(onEscape);
  onEscapeRef.current = onEscape;

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.keyCode === 27 && onEscapeRef.current) onEscapeRef.current();
    };
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, []);
};
