export const paymentFormText = {
  subscriptionPayment: `
Your current total due and all renewal fees will be charged to the
payment method you provide, and your membership will automatically
renew at the rates listed above, unless you cancel your membership
at least 24-hours before the end of a membership period. You can
cancel or modify your membership settings at any time by returning
to this page.`,

  irlPayment: `You must be 13 or older to attend our in-person classes. All purchases are non-refundable after 9pm the day prior to your scheduled class. Unlimited members who cancel after 9pm the day prior to class or do not show up will be charged a $5 fee. If you need to cancel your reservation, you can do so from the class detail page. By signing up for and/or attending our classes, you acknowledge and agree that our classes require strenuous physical activity and you assume full responsibility for any and all injuries or damage which may be sustained or aggravated by you in relation to our classes or the facilities where they take place.`,
  irlSubscriptionPayment: `By checking the Add Membership box, the payment method you provide will be charged $49 and your membership will automatically renew at $49/mo plus applicable taxes. You must register for classes in order to attend, and can cancel your class registration up to 24 hours in advance. You will be charged $5 if you do not attend a class that you are registered for. You can cancel your membership at any time on the account page.`,
  onetimePayment: `Your payment will be charged to the payment method you provide.`,
  giftSubscriptionPayment: `Gift recipient must be located in the 50 United States or District of Columbia. Your membership gift order cannot be combined with any other offers, coupons, discounts, or promotions. Gift memberships are not gift cards, not refundable, and not redeemable for cash.`,
};
