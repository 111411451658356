import { SalesTaxCalculation } from "~/lib/client/useSalesTax";
import clsx from "clsx";
import React from "react";

const isInteger = (v: number) => v === Math.floor(v);

const formatPrice = (price: number) =>
  isInteger(price) ? price : price.toFixed(2);

const formatSalesTax = (salesTax: SalesTaxCalculation) =>
  salesTax.amount
    ? ` (includes $${formatPrice(salesTax.amount)} ${salesTax.description})`
    : "";

interface Props {
  total: number;
  salesTax?: SalesTaxCalculation;
}

export const TotalDisplay: React.FC<Props> = ({ salesTax, total }) => (
  <span
    className={clsx("form__row__value form__row__value--padded", {
      "form-text--strong":
        typeof salesTax === "undefined" || salesTax.calculated,
    })}
  >
    ${formatPrice(total + (salesTax?.amount ?? 0))}
    {salesTax && formatSalesTax(salesTax)}
  </span>
);
