const ALLOWED_ERROR_TYPES = [
  "card_error",
  "StripeCardError",
  "validation_error",
];

const PROMOTION_CODE_PARAM = "promotion_code";

export const DEFAULT_MESSAGE =
  "Sorry, but an unexpected error occurred. Please try again later. If things still don't work, please send an email to info@dancechurch.com. Thanks!";
export const COULDNT_CHARGE_CARD_MESSAGE =
  "Sorry, but we couldn't charge your credit card. Please try again or contact info@dancechurch.com for support.";
export const COULDNT_RESTART_SUBSCRIPTION_MESSAGE =
  "Sorry, but we couldn't restart your membership. Please try again or contact info@dancechurch.com for support.";
export const INVALID_PROMOTION_CODE_MESSAGE =
  "Sorry, but that's not a valid promotion code.";

// XXX this is a good moment to dwell on the complexities of typing stripe
// across both the client and server. In particular, stripe's JS library
// and stripe's Node library aren't the same! Types that seem like they should
// be equivalent in both aren't, necessarily. We sort of mix and match things
// right now, and trust to nextjs's build process to sort it out. It's...
// maybe not ideal. Rather than draw from types in both librariers here in
// shared/stripe, we cheat a bit... but perhaps we shouldn't. -Dave

interface StripeError {
  param?: string;
  type?: string;
  message?: string;
}

interface StripeThrownError {
  raw: StripeError;
}

/**
 * Given a raw stripe error instance, return an error message suitable to
 * show to our users. For a certain class of stripe errors, this is whatever
 * text stripe sends to us; for others, we surface a very generic error message.
 */
export const getUserFacingErrorMessage = (
  error: StripeError | StripeThrownError
): string => {
  const type = "raw" in error ? error.raw.type : error.type;
  const message = "raw" in error ? error.raw.message : error.message;
  const param = "raw" in error ? error.raw.param : error.param;

  if (param === PROMOTION_CODE_PARAM) {
    return INVALID_PROMOTION_CODE_MESSAGE;
  }

  // Return our default message for all errors except for card errors, and
  // also return a default if stripe didn't provide a message for some reason.
  return ALLOWED_ERROR_TYPES.includes(type ?? "")
    ? message ?? DEFAULT_MESSAGE
    : DEFAULT_MESSAGE;
};
