import React, { FC } from "react";

export const FormRow: FC<{ label: string; labelFor?: string }> = (props) => (
  <div className="form__row">
    <label className="form__row__label" htmlFor={props.labelFor}>
      {props.label}
    </label>
    {props.children}
  </div>
);

export const FormValue: FC = (props) => (
  <div className="form__value">{props.children}</div>
);

export const FormContainer: FC = (props) => (
  <div className="form">{props.children}</div>
);
