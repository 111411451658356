import { Button } from "~/components/layout/Button";
import { ForwardText } from "~/components/layout";
import { PaymentMethodApi } from "dcgo-contracts";
import React from "react";

/** Render a payment method section. */
export const PaymentMethodSection = ({
  defaultPaymentMethod,
  onRequestUpdate,
}: {
  defaultPaymentMethod?: PaymentMethodApi.PaymentMethod | null;
  onRequestUpdate: () => void;
}) => {
  const hasPayment = Boolean(defaultPaymentMethod?.last4);
  return (
    <div className="form__row">
      <span className="form__row__label">Card Info</span>
      <span className="form__row__value form__row__value--row">
        {(defaultPaymentMethod && (
          <p>
            {defaultPaymentMethod.brand} *{defaultPaymentMethod.last4} exp.{" "}
            {defaultPaymentMethod.expMonth}/{defaultPaymentMethod.expYear}
          </p>
        )) || <p>No card on file</p>}
        <Button grayCaps onClick={() => onRequestUpdate()}>
          <ForwardText>{hasPayment ? "update" : "add"} card</ForwardText>
        </Button>
      </span>
    </div>
  );
};
