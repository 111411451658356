import { assertNotNil } from "~/lib/shared/assert";
import { BackText } from "~/components/layout";
import { Button } from "~/components/layout/Button";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  DEFAULT_MESSAGE,
  getUserFacingErrorMessage,
} from "~/lib/shared/stripeErrors";
import { PaymentMethodFormData, PaymentMethodFormProps } from "./types";
import { PaymentMethodSection } from "../SubscriptionForm/ExistingMembership/PaymentMethodSection";
import { SubmitHandler, UnpackNestedValue, useForm } from "react-hook-form";
import { useAccount } from "~/lib/client/account";
import { useTicketOrder } from "~/components/irl/order/context";
import clsx from "clsx";
import React, { ReactElement, useEffect, useState } from "react";

/**--------------------------------------------------------------------
 * PaymentMethodForm :: our low-level stripe credit card form
 *---------------------------------------------------------------------*/

const joinNames = (names: (string | undefined)[]) =>
  names.filter((name) => Boolean(name)).join(" ");

const STRIPE_CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid" as const,
  style: {
    base: {
      "::placeholder": {
        color: "rgba(255,255,255,0.5)",
      },
      iconColor: "#d2fe53",
      color: "#ffffff",
      fontWeight: 500,
      fontFamily: "GT America, Roboto, Open Sans, sans-serif",
      fontSize: "17px",
      fontSmoothing: "antialiased",
    },
    invalid: {
      iconColor: "#ff1c00",
      color: "#ff1c00",
    },
  },
};
/** Our primary credit card entry form UI + functionality. */
export const PaymentMethodForm = <D extends PaymentMethodFormData>({
  onPaymentMethod,
  onZipCode,
  onProcessPaymentMethod,
  onCancel,
  buttonText,
  cancelButton,
  children,
  errorMessage: membershipErrorMessage,
  finePrint,
  waiversAccepted,
  isOpenPlatform,
}: PaymentMethodFormProps<D>) => {
  const { acceptWaivers, order } = useTicketOrder();
  const { waiversToAccept } = order;
  const elements = useElements();
  const stripe = useStripe();
  const [
    {
      account: { paymentMethod: defaultPaymentMethod },
    },
  ] = useAccount();
  const [isProcessingForm, setProcessingForm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [useDefaultCard, setUseDefaultCard] = useState<boolean>(true);
  const { register, handleSubmit, errors: formErrors } = useForm();
  const [
    {
      profile: { email, firstName, lastName, phone },
    },
  ] = useAccount();

  useEffect(() => {
    // if you have a saved card, make sure we let the caller know its zip code
    if (
      defaultPaymentMethod &&
      defaultPaymentMethod.zipCode &&
      useDefaultCard &&
      onZipCode
    ) {
      onZipCode(defaultPaymentMethod.zipCode);
    }
  }, [defaultPaymentMethod, useDefaultCard, onZipCode]);

  // Bail if any of the things aren't yet ready
  if (!stripe || !elements) {
    return null;
  }

  /** Handler invoked on submit, after all form data is client-side validated. */
  const processForm: SubmitHandler<D> = async (
    data: UnpackNestedValue<D>
  ): Promise<void> => {
    setProcessingForm(true);

    // Gin up something like a useful name, in a all cases
    const name = data.fullName || joinNames([firstName, lastName]);
    setErrorMessage(null);

    let paymentMethodId = useDefaultCard ? defaultPaymentMethod?.id : undefined;
    // Attempt to create a payment method by calling stripe directly
    // (we don't send credit card details to our server, of course!)
    if (!paymentMethodId) {
      const card = elements.getElement(CardElement);

      // This is highly unusual but we should probably error gracefully!
      if (!card) {
        setErrorMessage(DEFAULT_MESSAGE);
        setProcessingForm(false);
        return;
      }
      const {
        paymentMethod: newPaymentMethod,
        error: paymentMethodError,
      } = await stripe.createPaymentMethod({
        type: "card",
        card,
        billing_details: {
          ...(email && { email }),
          ...(phone && { phone }),
          ...(name && { name }),
        },
      });

      if (paymentMethodError) {
        console.error(
          "Stripe.tsx processForm() stripe.createPaymentMethod :: stripe error:",
          paymentMethodError
        );
        setErrorMessage(getUserFacingErrorMessage(paymentMethodError));
        setProcessingForm(false);
        return;
      } else {
        assertNotNil(newPaymentMethod, "Stripe.tsx: paymentMethod");
        paymentMethodId = newPaymentMethod.id;
      }
    }

    assertNotNil(paymentMethodId, "Stripe.tsx: paymentMethodId");

    if (onPaymentMethod) onPaymentMethod(paymentMethodId);

    if (onProcessPaymentMethod) {
      // Joshua: if waivers exist and the checkbox is checked, accept the waivers
      if (waiversToAccept.length > 0 && waiversAccepted === true) {
        acceptWaivers(waiversToAccept);
      }

      const result = await onProcessPaymentMethod(paymentMethodId);

      if (!result.success) {
        setErrorMessage(result.message);
        setProcessingForm(false);
      }
    } else {
      setProcessingForm(false);
    }
  };

  const waiverChecker: SubmitHandler<D> = (data: UnpackNestedValue<D>) => {
    // Joshua: if waivers exist and arne't accepted, don't process the form
    if (waiversToAccept.length > 0 && waiversAccepted !== true) {
      console.log("waivers not accepted");
      // Joshua: dont block purhcase due to waivers
      // return false;
    }

    // console.log("waivers accepted or non-existant!");
    processForm(data);
  };

  const displayError = errorMessage || membershipErrorMessage;

  // The actual rendering of our payment method form
  return (
    <div className="stripe_container">
      <form onSubmit={handleSubmit(waiverChecker)} className="form">
        {defaultPaymentMethod && useDefaultCard ? (
          <PaymentMethodSection
            defaultPaymentMethod={defaultPaymentMethod}
            onRequestUpdate={() => setUseDefaultCard(false)}
          />
        ) : (
          <>
            <div className="form__row">
              <label className="form__row__label" htmlFor="fullName">
                Full Name
              </label>
              <div className="form__row__value">
                <input
                  className="form__input"
                  id="fullName"
                  type="text"
                  name="fullName"
                  placeholder="First and Last Name"
                  ref={register({
                    required: "Please enter your name",
                  })}
                  defaultValue={joinNames([firstName, lastName])}
                />
                {formErrors.fullName && (
                  <div className="form__error">
                    {formErrors.fullName.message}
                  </div>
                )}
              </div>
            </div>
            <div className="form__row">
              <span className="form__row__label">Card Info</span>
              <div className="form__row__value form__row__value--padded">
                <CardElement
                  options={STRIPE_CARD_ELEMENT_OPTIONS}
                  onChange={(e) =>
                    onZipCode && onZipCode(e.value?.postalCode || "")
                  }
                />
              </div>
            </div>
          </>
        )}

        {
          // Render children, if any, passing in stuff from the useForm() hook.
          React.Children.map(children, (child) => {
            const reactChild = child as ReactElement;
            const props = reactChild?.props || {};
            const key = reactChild?.key;

            const propsWithInjection = { ...props, register, formErrors };

            return key
              ? React.createElement(reactChild.type, propsWithInjection)
              : child;
          })
        }

        {displayError && (
          <div className="form__row form__row--last form__row--right">
            <div className="form__error">{displayError}</div>
          </div>
        )}

        <div
          className={clsx("form__row form__row--last", {
            "form__row--between": onCancel,
          })}
        >
          {cancelButton ? cancelButton : null}
          {onCancel && (
            <Button grayCaps onClick={onCancel}>
              <BackText>Cancel</BackText>
            </Button>
          )}
          <Button pill submit disabled={isProcessingForm}>
            {isProcessingForm ? "Processing..." : buttonText}
          </Button>
        </div>
        {!isOpenPlatform && (
          <p
            className="detail_text "
            dangerouslySetInnerHTML={{
              __html: `SUBSCRIPTION TERMS <br> ${finePrint}`,
            }}
          />
        )}
      </form>
    </div>
  );
};
