/** Assert a formal boolean's truth. */
export function assertTrue(value: boolean, message?: string): asserts value {
  if (!value) {
    throw new Error(`${message ?? "assertTrue failed"}: ${value}`);
  }
}

/** Assert that a value is not undefined or null. */
export function assertNotNil(value: unknown, message?: string): asserts value {
  if (typeof value === "undefined" || value === null) {
    throw new Error(`${message ?? "assertNotNil failed"}: ${value}`);
  }
}
